import { MoodResponse } from '@room/mood-prompt/mood-prompt.model';
import { props } from '@ngrx/store';
import { createAction } from '@common/utils';
import { GroupMember, Participant, sessionFeatureKey } from './session.model';
export interface UpdateParticipantPayload<T extends Participant> {
  id: string;
  participant: PartialDeep<Omit<T, 'id'>>;
}

export enum SessionActionTypes {
  muteMouse = `/${sessionFeatureKey}muteMouse`,
  muteMouseSuccess = `/${sessionFeatureKey}muteMouseSuccess`,
  muteMouseError = `/${sessionFeatureKey}muteMouseError`,
  unmuteMouse = `/${sessionFeatureKey}unmuteMouse`,
  unmuteMouseSuccess = `/${sessionFeatureKey}unmuteMouseSuccess`,
  unmuteMouseError = `/${sessionFeatureKey}unmuteMouseError`,
}

export const muteMouse = createAction(
  SessionActionTypes.muteMouse,
  props<{ id: string }>(),
);

export const muteMouseSuccess = createAction(
  SessionActionTypes.muteMouseSuccess,
  props<{ id: string; isMouseClickMuted: boolean }>(),
);

export const muteMouseError = createAction(
  SessionActionTypes.muteMouseError,
  props<{ id: string }>(),
);

export const unmuteMouse = createAction(
  SessionActionTypes.unmuteMouse,
  props<{ id: string }>(),
);

export const unmuteMouseSuccess = createAction(
  SessionActionTypes.unmuteMouseSuccess,
  props<{ id: string; isMouseClickMuted: boolean }>(),
);

export const unmuteMouseError = createAction(
  SessionActionTypes.unmuteMouseError,
  props<{ id: string }>(),
);

export const updateAppointmentId = createAction(
  `/${sessionFeatureKey}/updateAppointmentId`,
  props<{
    participant: Participant;
    memberId: string;
    appointmentId: string;
  }>(),
);

export const updateAppointmentIdSuccess = createAction(
  `/${sessionFeatureKey}/updateAppointmentIdSuccess`,
  props<{ participant: Partial<Participant>; appointmentId: string }>(),
);

export const updateAppointmentIdError = createAction(
  `/${sessionFeatureKey}/updateAppointmentIdError`,
  props<{
    participant: Partial<Participant>;
    appointmentId: string;
    error: any;
  }>(),
);

export const kickParticipantMember = createAction(
  `/${sessionFeatureKey}/kickParticipantMember`,
  props<{ participant: Participant; member: GroupMember }>(),
);

export const kickParticipantMemberSuccess = createAction(
  `/${sessionFeatureKey}/kickParticipantMemberSuccess`,
  props<{ participant: Participant; member: GroupMember }>(),
);

export const kickParticipantMemberError = createAction(
  `/${sessionFeatureKey}/kickParticipantMemberError`,
  props<{ participant: Participant; member: GroupMember; error: any }>(),
);

export const updateMembersMood = createAction(
  `/${sessionFeatureKey}/updateMembersMood`,
  props<{
    participantId: string;
    member: GroupMember;
    moodResponse: MoodResponse;
  }>(),
);

export const memberMoodDeclined = createAction(
  `/${sessionFeatureKey}/memberMoodDeclined`,
  props<{
    participantId: string;
    member: GroupMember;
  }>(),
);

export const memberMoodDeclinedSuccess = createAction(
  `/${sessionFeatureKey}/memberMoodDeclinedSuccess`,
  props<{
    participantId: string;
    member: GroupMember;
  }>(),
);

export const updateMembersMoodSuccess = createAction(
  `/${sessionFeatureKey}/updateMembersMoodSuccess`,
  props<{
    participantId: string;
    member: GroupMember;
    moodResponse: MoodResponse;
  }>(),
);

export const initObserver = createAction(
  `/${sessionFeatureKey}/initObserver`,
  props<{ participant: Participant }>(),
);

export const triggerObserverInteraction = createAction(
  `/${sessionFeatureKey}/triggerObserverInteraction`,
);

export const addLocal = createAction(
  `/${sessionFeatureKey}/addLocal`,
  props<{ participant: Participant }>(),
);

export const addLocalSuccess = createAction(
  `/${sessionFeatureKey}/addLocalSuccess`,
  props<{ participant: Participant }>(),
);

export const addLocalError = createAction(
  `/${sessionFeatureKey}/addLocalError`,
  props<{ participant: Participant; error: any }>(),
);

export const addedRemotely = createAction(
  `/${sessionFeatureKey}/addedRemotely`,
  props<{ participant: Participant }>(),
);

export const updatedRemotely = createAction(
  `/${sessionFeatureKey}/updatedRemotely`,
  props<{ id: string; participant: Partial<Participant> }>(),
);

export const removedRemotely = createAction(
  `/${sessionFeatureKey}/removedRemotely`,
  props<{ id: string }>(),
);

export const setIsViewingPage = createAction(
  `/${sessionFeatureKey}/setIsViewingPage`,
  props<{ isViewingPage: boolean }>(),
);

export const setIsViewingPageSuccess = createAction(
  `/${sessionFeatureKey}/setIsViewingPageSuccess`,
  props<{ id: string; isViewingPage: boolean }>(),
);

export const setIsViewingPageError = createAction(
  `/${sessionFeatureKey}/setIsViewingPageError`,
  props<{ isViewingPage: boolean; error: any }>(),
);

export const addWaiting = createAction(
  `/${sessionFeatureKey}/addWaiting`,
  props<{ participant: Participant }>(),
);

export const addWaitingSuccess = createAction(
  `/${sessionFeatureKey}/addWaitingSuccess`,
  props<{ participant: Participant }>(),
);

export const addWaitingError = createAction(
  `/${sessionFeatureKey}/addWaitingError`,
  props<{ participant: Participant; error: any }>(),
);

export const admit = createAction(
  `/${sessionFeatureKey}/admit`,
  props<{ id: string }>(),
);

export const admitSuccess = createAction(
  `/${sessionFeatureKey}/admitSuccess`,
  props<{ id: string }>(),
);

export const admitError = createAction(
  `/${sessionFeatureKey}/admitError`,
  props<{ id: string; error: any }>(),
);

export const studentAdmissionFailed = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailed`,
  props<{ id: string }>(),
);

export const studentAdmissionFailedSetSuccess = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailedSetSuccess`,
  props<{ id: string }>(),
);

export const studentAdmissionFailedSetError = createAction(
  `/${sessionFeatureKey}/studentAdmissionFailedSetError`,
  props<{ id: string }>(),
);

export const kick = createAction(
  `/${sessionFeatureKey}/kick`,
  props<{ id: string }>(),
);

export const kickSuccess = createAction(
  `/${sessionFeatureKey}/kickSuccess`,
  props<{ id: string }>(),
);

export const kickError = createAction(
  `/${sessionFeatureKey}/kickError`,
  props<{ id: string; error: any }>(),
);

export const kicked = createAction(`/${sessionFeatureKey}/kicked`);

export const removeOfflineSuccess = createAction(
  `/${sessionFeatureKey}/removeOfflineSuccess`,
  props<{ id: string }>(),
);

export const removeOfflineError = createAction(
  `/${sessionFeatureKey}/removeOfflineError`,
  props<{ id: string; error: any }>(),
);

export const setYoutubeInteractionPending = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPending`,
  props<{ isPending: boolean }>(),
);

export const setYoutubeInteractionPendingSuccess = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPendingSuccess`,
  props<{ id: string; isPending: boolean }>(),
);

export const setYoutubeInteractionPendingError = createAction(
  `/${sessionFeatureKey}/setYoutubeInteractionPendingError`,
  props<{ isPending: boolean; error: any }>(),
);

export const setLocalOffline = createAction(
  `/${sessionFeatureKey}/setLocalOffline`,
  props<{ offline: boolean }>(),
);

export const leave = createAction(`/${sessionFeatureKey}/leave`);

export const kickDuplicateds = createAction(
  `/${sessionFeatureKey}/kickDuplicateds`,
);

export const kickDuplicatedsSuccess = createAction(
  `/${sessionFeatureKey}/kickDuplicatedsSuccess`,
);

export const kickDuplicatedsError = createAction(
  `/${sessionFeatureKey}/kickDuplicatedsError`,
  props<{ error: any }>(),
);

export const kickAllParticipants = createAction(
  `/${sessionFeatureKey}/kickAllParticipants`,
);

export const kickAllParticipantsSuccess = createAction(
  `/${sessionFeatureKey}/kickAllParticipantsSuccess`,
);

export const kickAllParticipantsError = createAction(
  `/${sessionFeatureKey}/kickAllParticipantsError`,
  props<{ error: any }>(),
);
