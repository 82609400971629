import { EntityState } from '@ngrx/entity';
import { MoodResponse } from '../../mood-prompt/mood-prompt.model';

export const sessionFeatureKey = 'session';

export interface SessionStateExtras {
  localId?: string;
  isObserverInteractionPending?: boolean;
}

export type SessionState = EntityState<Participant> & SessionStateExtras;

export type RTParticipant = Omit<Participant, 'isLocal'>;

export interface GroupMember {
  id?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  joinAppointmentId?: string;
  moodResponse?: Partial<MoodResponse>;
}

export interface Participant {
  id: string;
  isLocal: boolean;
  userId: string;
  displayName: string;
  type: ParticipantType;
  status: ParticipantStatus;
  isIPad: boolean;
  offline?: boolean;
  offlineAt?: number;
  deviceLabel?: string;
  isYoutubeInteractionPending: boolean;
  isViewingPage: boolean;
  omitFromSessionRecord: boolean;
  joinMuted: boolean;
  members?: Record<string, GroupMember>;
  isMouseClickMuted: boolean;
}

export enum ParticipantType {
  host = 'host',
  observer = 'observer',
  guest = 'guest',
  guestGroup = 'guest-group',
}

export enum ParticipantStatus {
  idle = 'idle',
  waiting = 'waiting',
  entering = 'entering',
  joined = 'joined',
  dismissed = 'dismissed',
  enteringTimeout = 'entering-timeout',
}
